import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { LoaderButton } from "../../../../Components/commonFunction/Loader"; // Ensure this is imported correctly
import { postStudentQuestionRatingApi } from "../../../../admin/helper/apis/evaluation/evaluationApis";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../../admin/helper/ErrorMessage";
import { DataEmpty, requiredStar } from "../../../../Components/commonFunction/CommonFunction";
import { localStorageAuthUserData } from "../../../../admin/helper/AuthUserData";

const EvaluationQuestionFrom = ({ show, hide, updateAfterRes }) => {
  const { evaluationsData } = show;
  const { courseId, evaluations } = evaluationsData || {};
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const mainId = detail?._id;

  // Initialize form state with React Hook Form
  const {
    handleSubmit,
    reset,
    formState: { errors },
    register,  // use register for dynamic field registration
    setValue,  // To set values dynamically
    getValues, // To get form values dynamically
    clearErrors, // To clear errors dynamically
  } = useForm();

  const [formLoading, setFormLoading] = useState(false);
  const [values, setValues] = useState([]); // Initialize as an array to hold multiple ratings for different questions
  const [questionCommit, setQuestionCommit] = useState({
    commitValue: "",
    anyReason: false,
  });

  // Handles rating change
  const handleSliderChange = (score, evaluationName, questionid) => {
    setValues((prevValues) => {
      // Check if this evaluation already has a rating
      const existingEntryIndex = prevValues.findIndex(
        (entry) => entry.evaluation_name === evaluationName
      );

      if (existingEntryIndex !== -1) {
        // If the evaluation already exists in the array, update its score
        const updatedValues = [...prevValues];
        updatedValues[existingEntryIndex] = {
          ...updatedValues[existingEntryIndex],
          score: score,
        };
        return updatedValues;
      } else {
        // Otherwise, create a new entry for this evaluation question
        const newEntry = {
          evaluation_name: evaluationName,
          score: score,
          id: questionid,
        };
        return [...prevValues, newEntry];
      }
    });

    // Set value dynamically for react-hook-form registration
    setValue(evaluationName, score); // This ensures the form knows that this field has been rated
    clearErrors(evaluationName); // Clear the error once a rating is selected
  };

  // Handle form submission
  const onSubmit = async () => {
    setFormLoading(true);
    const payload = {
      courseId: courseId?._id,
      studentId: mainId,
      evaluations: values?.map((answerItem) => {
        return {
          id: answerItem?.id,
          rating: answerItem?.score,
        };
      }),
      comment: questionCommit?.commitValue,
      contactAdmin: questionCommit?.anyReason,
    };
    const res = await postStudentQuestionRatingApi(payload);
    if (res.status) {
      hide();
      updateAfterRes();
      reset({
        questionN: "",
      });
    }
    setFormLoading(false);
  };

  // Handle reason change (for N/Y buttons)
  const handleReasonChange = (value) => {
    setQuestionCommit({
      ...questionCommit,
      anyReason: value,
    });
  };

  return (
    <div>
      {show?.type ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[50%] w-[90%] popup">
              {/* Content */}
              <div className="border-0 rounded-xl shadow-xl relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* Header */}
                <div className="flex items-center justify-between p-4 border-b border-solid border-[#EEEEEE] rounded-t-lg">
                  <h3 className="text-2xl font-semibold text-[#333333]">
                    Evaluation Question
                  </h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-[#FF4F4F] text-2xl font-normal outline-none focus:outline-none"
                    onClick={() => hide()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                {/* Body */}
                {evaluations?.length > 0 ? (
                  <div className="relative px-8 py-6 flex-auto">
                    <div className="form">
                      <div>
                        {evaluations?.map((evaluationQuestItem) => {
                          const {
                            id: Questions,
                            rating,
                            question,
                            _id,
                            maxRating,
                          } = evaluationQuestItem;

                          return (
                            <>
                            {Questions !== null && <div key={_id} className="mb-8">
                              <label className="mb-2">
                                {question} {requiredStar}
                              </label>
                              <div className="flex justify-start gap-6 mb-6">
                                {/* Rating buttons */}
                                {Array.from({ length: maxRating }).map(
                                  (label, index) => (
                                    <button
                                      key={index}
                                      onClick={() =>
                                        handleSliderChange(
                                          index + 1,
                                          question,
                                          Questions?._id
                                        )
                                      }
                                      type="button"
                                      className={`rating-button px-6 py-3 rounded-full text-white font-semibold transition-all duration-300 
                                        ${
                                          values?.find(
                                            (v) => v.evaluation_name === question
                                          )?.score === index + 1
                                            ? "bg-gradient-to-r from-green-400 to-green-600 border-2 border-green-700"
                                            : "bg-gray-200 border-2 border-gray-400"
                                        } 
                                        hover:bg-gradient-to-r hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 shadow-md`}
                                    >
                                      {index + 1}
                                    </button>
                                  )
                                )}
                              </div>

                              {/* Validation error */}
                              {errors?.[question] && (
                                <p className="text-red-500 text-sm italic">
                                  {errorMessageShow(
                                    errorEndPoint?.THIS_FIELD_IS_REQUIRED
                                  )}
                                </p>
                              )}

                              {/* Register each question with required validation */}
                              <input
                                type="hidden"
                                {...register(question, {
                                  required: "This field is required",
                                })}
                              />
                            </div>}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <DataEmpty />
                )}

                <div className="px-8 pb-6">
                  <div className="">
                    <label htmlFor="" className="mb-2">
                      Comments
                    </label>
                    <input
                      type="text"
                      value={questionCommit?.commitValue}
                      onChange={(e) => {
                        setQuestionCommit({
                          ...questionCommit,
                          commitValue: e.target.value,
                        });
                      }}
                      className="block w-full rounded-md h-[50px]  py-1 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="" className="mb-2">
                      Would you like to be contacted for any reason?
                    </label>
                    <div className="flex gap-3">
                      <button
                        className={`${
                          !questionCommit?.anyReason
                            ? "bg-red-500"
                            : "bg-gray-500"
                        } flex justify-center items-center w-full mx-3 rounded-lg px-3 py-3 md:text-xl text-base font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-[70px]`}
                        onClick={() => handleReasonChange(false)} // N
                      >
                        N
                      </button>
                      <button
                        className={`flex justify-center items-center mx-3 w-full rounded-lg px-3 py-3 md:text-xl text-base font-semibold leading-6 text-white ${
                          questionCommit?.anyReason
                            ? "bg-red-500"
                            : "bg-gray-500"
                        } shadow-sm hover:bg-red-600 hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-[70px]`}
                        onClick={() => handleReasonChange(true)} // Y
                      >
                        Y
                      </button>
                    </div>
                  </div>
                </div>

                {/* Footer */}
                <div className="flex items-center justify-end px-8 py-4 border-t border-solid border-[#EEEEEE] rounded-b-lg gap-6">
                  <button
                    className="text-white bg-gray-300 font-medium px-8 py-3 text-sm rounded-lg hover:bg-gray-400 transition-all duration-200"
                    type="button"
                    onClick={() => hide()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-gradient-to-r from-red-500 to-red-600 text-white active:bg-emerald-600 font-medium text-sm px-8 py-3 rounded-lg shadow-lg hover:shadow-xl outline-none focus:outline-none transition-all duration-200"
                    type="button"
                    onClick={handleSubmit(onSubmit)} // Trigger form validation before submission
                    disabled={Object.keys(errors).length > 0 || formLoading} // Disable submit if there are validation errors
                  >
                    {!formLoading ? "Add" : <LoaderButton />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EvaluationQuestionFrom;
