import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { LoaderButton } from "../../../../Components/commonFunction/Loader";
import { postStudentQuestionRatingApi } from "../../../../admin/helper/apis/evaluation/evaluationApis";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../../admin/helper/ErrorMessage";
import { DataEmpty } from "../../../../Components/commonFunction/CommonFunction";
import { localStorageAuthUserData } from "../../../../admin/helper/AuthUserData";

const EvaluationComplete = ({ show, hide, updateAfterRes }) => {
  const { evaluationsData } = show;
  const { courseId, evaluations, submitted } = evaluationsData || {};
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const mainId = detail?._id;

  // Initialize form state
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [formLoading, setFormLoading] = useState(false);
  const [values, setValues] = useState([]);
  const [questionCommit, setQuestionCommit] = useState({
    commitValue: "",
    anyReason: "",
  });

  // Handles rating change
  const handleSliderChange = (score, mainId, evaluationName) => {
    setValues((prevValues) => {
      const existingEntryIndex = prevValues.findIndex(
        (entry) => entry.evaluation_name === evaluationName
      );
      if (existingEntryIndex !== -1) {
        const updatedValues = [...prevValues];
        updatedValues[existingEntryIndex] = {
          ...updatedValues[existingEntryIndex],
          score: score,
        };
        return updatedValues;
      } else {
        const newEntry = {
          evaluation_name: evaluationName,
          score: score,
          id: mainId,
        };
        return [...prevValues, newEntry];
      }
    });
  };
  // Handle form submission
  const onSubmit = async (reasonType) => {
    setFormLoading(true);
    const payload = {
      courseId: courseId?._id,
      studentId: mainId,
      evaluations: values?.map((answerItem) => {
        return {
          id: answerItem?.id,
          rating: answerItem?.score,
        };
      }),
      comment: questionCommit?.commitValue,
      contactAdmin: reasonType,
    };
    const res = await postStudentQuestionRatingApi(payload);
    if (res.status) {
      hide();
      updateAfterRes();
      reset({
        questionN: "",
      });
    }
    setFormLoading(false);
  };

  useEffect(() => {
    if (evaluationsData) {
      const { comment, evaluations, contactAdmin } = evaluationsData;
      const updatedValues = evaluations?.map((editEvaluationItem) => {
        return {
          score: editEvaluationItem?.rating,
          id: editEvaluationItem?._id,
        };
      });
      setValues(updatedValues);
      setQuestionCommit({ commitValue: comment, anyReason: contactAdmin });
      console.log(evaluationsData, "evaluationsDataevaluationsData");
    }
  }, [evaluationsData?.evaluations]);

  return (
    <div>
      {show?.compeleteType ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[50%] w-[90%] popup">
              {/* Content */}
              <div className="border-0 rounded-xl shadow-xl relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* Header */}
                <div className="flex items-center justify-between p-4 border-b border-solid border-[#EEEEEE] rounded-t-lg">
                  <h3 className="text-2xl font-semibold text-[#333333]">
                    Evaluation Question
                  </h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-[#FF4F4F] text-2xl font-normal outline-none focus:outline-none"
                    onClick={() => hide()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>

                {/* Body */}
                {evaluations?.length > 0 ? (
                  <div className="relative px-8 pt-6 flex-auto">
                    <div className="form">
                      <div>
                        {evaluations?.map((evaluationQuestItem) => {
                          const {
                            id: Questions,
                            rating,
                            question,
                            _id,
                            maxRating
                          } = evaluationQuestItem;

                          return (
                            <>
                            {rating !== 0 && <div key={_id} className="mb-8">
                              <label className="mb-2">
                                {question}
                              </label>
                              <div className="flex justify-start gap-6 mb-6">
                                {/* Rating buttons */}
                                {Array.from({ length: maxRating }).map(
                                  (label, index) => (
                                    <button
                                      key={index}
                                      disabled
                                      onClick={() =>
                                        handleSliderChange(
                                          index + 1,
                                          Questions,
                                          question
                                        )
                                      }
                                      className={`rating-button px-6 py-3 rounded-full text-white font-semibold transition-all duration-300 
                            ${
                              values?.find((v) => v.id === _id)?.score ===
                              index + 1
                                ? "bg-gradient-to-r from-green-400 to-green-600 border-2 border-green-700"
                                : "bg-gray-200 border-2 border-gray-400"
                            } 
                            hover:bg-gradient-to-r hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 shadow-md`}
                                    >
                                      {index+1}
                                    </button>
                                  )
                                )}
                              </div>
                              {/* Error message */}
                              {errors?.[question] && (
                                <p className="text-red-500 text-sm italic">
                                  {errorMessageShow(
                                    errorEndPoint?.THIS_FIELD_IS_REQUIRED
                                  )}
                                </p>
                              )}
                            </div>}
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : (
                  <DataEmpty />
                )}
                <div className="px-8 pb-6">
                <div className="">
                  <label htmlFor="" className="mb-2">Comments</label>
                  <input
                    type="text"
                    disabled
                    value={questionCommit?.commitValue}
                    onChange={(e) => {
                      setQuestionCommit({
                        ...questionCommit,
                        commitValue: e.target.value,
                      });
                    }}
                    className="block w-full rounded-md h-[50px]  py-1 text-gray-900  ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px]  placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3 border-1 border-[#B1B1B1]"
                  />
                </div>
                <div className="mt-3">
                  <label htmlFor="" className="mb-2">
                    Would you like to be contacted for any reason?
                  </label>
                  <div className="flex gap-3">
                    <button
                      disabled
                      className={` ${
                        !questionCommit?.anyReason
                          ? "bg-red-500"
                          : "bg-gray-500"
                      } flex justify-center items-center w-full rounded-lg px-3 py-3 md:text-xl text-base font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-[70px]`}
                      onClick={() => {
                        onSubmit(false);
                      }}
                    >
                      N
                    </button>
                    <button
                      disabled
                      className={`flex justify-center items-center w-full rounded-lg px-3 py-3 md:text-xl text-base font-semibold leading-6 text-white  ${
                        questionCommit?.anyReason ? "bg-red-500" : "bg-gray-500"
                      } shadow-sm hover:bg-red-600 hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-[70px]`}
                      onClick={() => {
                        onSubmit(true);
                      }}
                    >
                      Y
                    </button>
                  </div>
                </div>
                </div>

                {/* Footer */}
                <div className="flex items-center justify-end px-8 py-4 border-t border-solid border-[#EEEEEE] rounded-b-lg gap-6">
                  <button
                    className="text-white bg-gray-300 font-medium px-8 py-3 text-sm rounded-lg hover:bg-gray-400 transition-all duration-200"
                    type="button"
                    onClick={() => hide()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default EvaluationComplete;
