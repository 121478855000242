import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LOGO from "../../../images/logo-university.png";
import Login from "../../../../src/images/login-img.png";
import {
  errorMessageShow,
} from "../../../admin/helper/ErrorMessage";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  USER_RESET_PASSWORD,
} from "../../../admin/helper/ApiEndPoint";
import ToastHandle from "../../../admin/helper/ToastMessage";
import Lorder from "../../../admin/helper/Lorder";

const ResetPassword = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    getValues,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const submitHandle = async (data) => {
    setLoading(true);
    const { password } = data;
    const body = {
      token: id,
      newPassword: password,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}${USER_RESET_PASSWORD}`,
        body
      );
      if (response.status) {
        ToastHandle(response?.data?.message, "success");
        reset();
        navigate("/login");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        ToastHandle(error?.response?.data?.message, "danger");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="banner flex justify-center xl:items-center flex-wrap py-10 md:py-0">
        <div className="w-full md:w-1/2 px-[0] 2xl:px-[230px] mx-auto mb-4 lg:mb-0">
          <div className="2xl:absolute top-[40px] left-[40px] px-5">
            <img className="mb-20" src={LOGO} />
          </div>
          <h1 className="md:px-10 px-5 font-semibold md:text-4xl text-3xl mb-10 text-black">
            Reset Password
          </h1>
          <form
            className="md:px-10 px-5"
            onSubmit={handleSubmit(submitHandle)}
          >
            <div className="mb-[25px]">
              <label
                htmlFor="password"
                className="block md:text-xl text-base font-medium leading-4 text-[#263A43]"
              >
                New Password
              </label>
              <div className="mt-3">
                <input
                  name="password"
                  type="password" 
                  autoComplete="new-password"
                  {...register("password", {
                    required: "Password is required", 
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters long",
                    },
                  })}
                  placeholder="Enter new password"
                  className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px] placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
                />
                {errors.password?.message && (
                  <div className="text-red-600 text-sm mt-2">
                    {errorMessageShow(errors.password.message)}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-[25px]">
              <label
                htmlFor="confirmPassword"
                className="block md:text-xl text-base font-medium leading-4 text-[#263A43]"
              >
                Confirm Password
              </label>
              <div className="mt-3">
                <input
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                  {...register("confirmPassword", {
                    required: "Confirm password is required",
                    validate: (value) =>
                      value === getValues("password") ||
                      "Passwords do not match", 
                  })}
                  placeholder="Confirm your password"
                  className="block w-full rounded-md border-0 h-[50px] py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-[#8E8E8E] focus:ring-1 md:placeholder:text-[17px] placeholder:text-[14px] focus:ring-inset focus:ring-[#CC4D1D] sm:text-sm sm:leading-6 px-3"
                />
                {errors.confirmPassword?.message && (
                  <div className="text-red-600 text-sm mt-2">
                    {errorMessageShow(errors.confirmPassword.message)}
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <button
                type="submit"
                className="login-btn flex justify-center items-center w-full rounded-lg px-3 py-3 md:text-xl text-base font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-[70px]"
              >
                {!loading ? "Submit" : <Lorder />}
              </button>
            </div>
          </form>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/2 xl:p-20 p-8 flex items-start lg:items-center right-section md:mt-0 mt-5">
          <img className="" src={Login} />
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
