import {
  FaRegAddressCard,
  FaRegRectangleList,
  FaWpforms,
} from "react-icons/fa6";
import { RiExchangeDollarLine } from "react-icons/ri";
import { FaRegHospital } from "react-icons/fa";
import { CiSettings, CiViewList } from "react-icons/ci";
import { SiRubygems } from "react-icons/si";
import { MdOutlineManageAccounts } from "react-icons/md";
import { PiCertificate, PiStudent } from "react-icons/pi";
import { TbReportAnalytics } from "react-icons/tb";
import { RiLogoutCircleRLine } from "react-icons/ri";

export const navLinks = [
  {
    label: "Dashboard",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M14.6667 7.5C14.6667 6.69333 14.1267 5.66 13.4667 5.2L9.34671 2.31333C8.41337 1.66 6.91337 1.69333 6.01337 2.39333L2.42004 5.19333C1.82004 5.66 1.33337 6.65333 1.33337 7.40666V12.3467C1.33337 13.8933 2.59337 15.16 4.14004 15.16H11.86C13.4067 15.16 14.6667 13.8933 14.6667 12.3533V10.2867"
          stroke=""
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 12.4933V10.4933"
          stroke=""
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    link: "/",
  },
  {
    label: "Student Management",
    icon: <FaRegAddressCard className="text-lg" />,
    link: "/student-management",
  },

  {
    label: "Course Management",
    icon: <FaWpforms className="text-lg" />,
    link: "/course-management",
  },
  {
    label: "Instructor Management",
    icon: <RiExchangeDollarLine className="text-lg" />,
    link: "/instructor-management",
  },
  {
    label: "Evaluation Management",
    icon: <FaRegRectangleList className="text-lg" />,
    link: "/evaluation-management",
    submenu: [
      {
        label: "Evaluation",
        link: "/evaluation-management",
        icon: <CiViewList className="text-lg" />,
      },

      // {
      //   label: "Student Evaluation",
      //   link: "/Student-evaluation",
      //   icon: <PiStudent className="text-lg" />,
      // },
      // {
      //   label: "Evaluation Report",
      //   link: "/evaluation-report",
      //   icon: <TbReportAnalytics className="text-lg" />,
      // },
    ],
  },
  {
    label: "EMS Board Management",
    icon: <SiRubygems className="text-lg" />,
    link: "/ems-board-list",
  },
  {
    label: "Agency Management",
    icon: <MdOutlineManageAccounts className="text-lg" />,
    link: "/agency-management-list",
  },
  {
    label: "Hospital Management",
    icon: <FaRegHospital className="text-lg" />,
    link: "/hospital-management",
  },
  // {
  //   label: "Add Certificate",
  //   icon: <PiCertificate className="text-lg" />,
  //   link: "/add-certificate",
  // },

  {
    label: "Settings",
    icon: <CiSettings className="text-lg" />,
    link: "/setting",
  },
  {
    label: "Logout",
    icon: <RiLogoutCircleRLine className="text-lg" />,
    link: "/logout",
  },
];

export const instructorNavLinks = [
  {
    label: "Dashboard",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M14.6667 7.5C14.6667 6.69333 14.1267 5.66 13.4667 5.2L9.34671 2.31333C8.41337 1.66 6.91337 1.69333 6.01337 2.39333L2.42004 5.19333C1.82004 5.66 1.33337 6.65333 1.33337 7.40666V12.3467C1.33337 13.8933 2.59337 15.16 4.14004 15.16H11.86C13.4067 15.16 14.6667 13.8933 14.6667 12.3533V10.2867"
          stroke=""
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 12.4933V10.4933"
          stroke=""
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    link: "/instructor/dashboard",
  },
  {
    label: "Student Management",
    icon: <FaRegAddressCard className="text-lg" />,
    link: "/instructor/student-management",
  },

  {
    label: "Course Management",
    icon: <FaWpforms className="text-lg" />,
    link: "/instructor/course-management",
  },
  {
    label: "Evaluation Management",
    icon: <FaRegRectangleList className="text-lg" />,
    link: "/instructor/evaluation-management",
    submenu: [
      {
        label: "Evaluation",
        link: "/instructor/evaluation-management",
        icon: <CiViewList className="text-lg" />,
      },

      // {
      //   label: "Student Evaluation",
      //   link: "/instructor/Student-evaluation",
      //   icon: <PiStudent className="text-lg" />,
      // },
      // {
      //   label: "Evaluation Report",
      //   link: "/instructor/evaluation-report",
      //   icon: <TbReportAnalytics className="text-lg" />,
      // },
    ],
  },
  {
    label: "Agency Management",
    icon: <MdOutlineManageAccounts className="text-lg" />,
    link: "/agency-management-list",
  },
  // {
  //   label: "Add Certificate",
  //   icon: <PiCertificate className="text-lg" />,
  //   link: "/instructor/add-certificate",
  // },

  {
    label: "Settings",
    icon: <CiSettings className="text-lg" />,
    link: "/setting",
  },
  {
    label: "Logout",
    icon: <RiLogoutCircleRLine className="text-lg" />,
    link: "/logout",
  },
];

export const studentNavLinks = [
  {
    label: "Dashboard",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
      >
        <path
          d="M14.6667 7.5C14.6667 6.69333 14.1267 5.66 13.4667 5.2L9.34671 2.31333C8.41337 1.66 6.91337 1.69333 6.01337 2.39333L2.42004 5.19333C1.82004 5.66 1.33337 6.65333 1.33337 7.40666V12.3467C1.33337 13.8933 2.59337 15.16 4.14004 15.16H11.86C13.4067 15.16 14.6667 13.8933 14.6667 12.3533V10.2867"
          stroke=""
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M8 12.4933V10.4933"
          stroke=""
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    link: "/student/dashboard",
  },

  {
    label: "Course Browsing",
    icon: <FaWpforms className="text-lg" />,
    link: "/student/courses",
  },
  {
    label: "My Courses",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="15"
        viewBox="0 0 16 15"
        fill="none"
      >
        <path
          d="M4.34233 0.833344C3.47299 0.919343 2.88766 1.11268 2.45033 1.55068C1.66699 2.33534 1.66699 3.59868 1.66699 6.12601V8.80601C1.66699 11.3327 1.66699 12.5967 2.45033 13.382C3.23366 14.1673 4.49499 14.1667 7.01699 14.1667H8.35499C10.877 14.1667 12.1383 14.1667 12.9217 13.382C13.633 12.6687 13.699 11.5707 13.705 9.48468"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.01682 4.16669L7.68548 6.50002C8.05882 7.24002 8.52748 7.43335 9.69215 7.50002C10.6181 7.47735 11.1555 7.36802 11.6142 6.96935C11.9268 6.69735 12.0681 6.28735 12.1368 5.87935L12.3661 4.50002M14.0382 3.16669V6.50002M5.73348 2.78869C6.79148 1.91069 7.73415 1.43935 9.68948 0.920685C9.91007 0.862446 10.1421 0.863596 10.3621 0.924019C12.0928 1.40002 13.0275 1.82269 14.2795 2.76269C14.3328 2.80269 14.3488 2.87735 14.3115 2.93269C13.9028 3.53402 12.9902 4.02135 10.7515 4.88935C10.2852 5.06877 9.76843 5.06591 9.30415 4.88135C6.92015 3.93469 5.82415 3.42802 5.69082 2.90202C5.68648 2.88103 5.68819 2.85924 5.69574 2.83918C5.7033 2.81912 5.71638 2.80161 5.73348 2.78869Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
    link: "/student/my-courses",
  },
  {
    label: "Evaluation",
    icon: <FaRegRectangleList className="text-lg" />,
    link: "/student/evaluation",
  },
  {
    label: "Certificate",
    icon: <PiCertificate className="text-lg" />,
    link: "/student/certificates",
  },

  {
    label: "Settings",
    icon: <CiSettings className="text-lg" />,
    link: "/setting",
  },
  {
    label: "Logout",
    icon: <RiLogoutCircleRLine className="text-lg" />,
    link: "/logout",
  },
];
