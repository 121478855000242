import React, { useEffect, useState } from "react";
import { TableLorder } from "../../../admin/helper/Lorder";
import { DataEmpty } from "../../../Components/commonFunction/CommonFunction";
import { studentEvaluationQuestionGetApi } from "../../../admin/helper/apis/student/StudentApis";
import { localStorageAuthUserData } from "../../../admin/helper/AuthUserData";
import SearchFilter from "../../../Components/commonFunction/SearchFilter";
import EvaluationQuestionFrom from "./evaluationQustionModel/EvaluationQuestionFrom";
import EvaluationComplete from "./evaluationQustionModel/EvaluationComplete";

const EvaluationTable = () => {
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const mainId = detail?._id;
  const [evaluationQuestionModel, setEvaluationQuestionModle] = useState({
    type: false,
    compeleteType: false,
  });
  const [studentQuestionLoading, setStudentQuestionLoading] = useState(false);
  const [data, setData] = useState([]);
  let filterData = SearchFilter(data, "");

  const fetechStudentQuestionData = async () => {
    setStudentQuestionLoading(true);
    try {
      const { response } = await studentEvaluationQuestionGetApi(mainId);
      setData(response?.data?.data);
    } catch (error) {}
    setStudentQuestionLoading(false);
  };

  useEffect(() => {
    if (mainId) {
      fetechStudentQuestionData();
    }
  }, [mainId]);

  return (
    <div className="table-section table-responsive rounded-[15px] w-full bg-white">
      {!studentQuestionLoading ? (
        <>
          {filterData?.length > 0 ? (
            <table className="w-full h-full table-auto table-layout-fixed">
              <thead className="bg-[#d9d9d9] h-[60px]">
                <tr>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Sr. No.
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    // onClick={() => handleSort("Name")}
                  >
                    <p className="flex items-center gap-2 tableHeading">
                      <span>Name of Agency</span>
                      <span className="iconSort"></span>
                    </p>
                  </th>
                  <th
                    scope="col"
                    className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filterData?.map((questionItem, questionIndex) => {
                  const { courseId, submitted } = questionItem;
                  console.log(questionItem, "questionItemquestionItem");
                  return (
                    <tr className=" h-[60px] border-b border-[#F4F5F9]">
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {questionIndex + 1}
                      </td>
                      <td className="text-[#89868D] text-sm  font-normal px-5 py-3">
                        {courseId?.name}
                      </td>

                      <td className="text-sm  font-normal px-5 py-3">
                        {submitted ? (
                          <button
                            onClick={() => {
                              setEvaluationQuestionModle({
                                compeleteType: true,
                                evaluationsData: questionItem,
                              });
                            }}
                            className="text-sm text-white rounded-[5px] bg-[#01AF7B] py-1.5 px-2.5"
                          >
                            Complete
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setEvaluationQuestionModle({
                                type: true,
                                evaluationsData: questionItem,
                              });
                            }}
                            className="text-sm text-white rounded-[5px] bg-[#FF0000] py-1.5 px-2.5"

                          >
                            Evaluation
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}
      <EvaluationQuestionFrom
        show={evaluationQuestionModel}
        hide={() => {
          setEvaluationQuestionModle({ type: false });
        }}
        updateAfterRes={fetechStudentQuestionData}
      />
      <EvaluationComplete
        show={evaluationQuestionModel}
        hide={() => {
          setEvaluationQuestionModle({ type: false });
        }}
      />
    </div>
  );
};

export default EvaluationTable;
