import React, { useEffect, useState } from "react";
import Coursedetailimg from "../../../../../images/course.png";
import { Link, useParams } from "react-router-dom";
import { TableLorder } from "../../../../helper/Lorder";
import { convertTo12HourFormat } from "../../../../helper/dateFormate";
import { FaEdit } from "react-icons/fa";
import { GrClose } from "react-icons/gr";
import CourseEditModel from "../courseEditModel/CourseEditModel";
import {
  customStyles,
  truncateLink,
} from "../../../../../Components/commonFunction/CommonFunction";
import {
  authKey,
  localStorageAuthUserData,
  paginationData,
} from "../../../../helper/AuthUserData";
import StudentBacheTable from "./studentBacheTable/StudentBacheTable";
import {
  GetCourseBatchCreateListApi,
  courseDetailGetListApi,
  studentsGetListApi,
} from "../../../../helper/apis/course/CourseApis";
import AddSectionForm from "./addSectionModel/AddSectionForm";
import SearchFilter from "../../../../../Components/commonFunction/SearchFilter";
import EditBatchForm from "./editBatchModel/EditBatchForm";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { assignCourseStudentEvaluationQuestionApi } from "../../../../helper/apis/evaluation/evaluationApis";
import ToastHandle from "../../../../helper/ToastMessage";
import { LoaderButton } from "../../../../../Components/commonFunction/Loader";

const CourseManagementProfileView = () => {
  const [editModel, setEditModel] = useState({ type: false });
  const loginData = localStorageAuthUserData();
  const { detail } = loginData || {};
  const { role } = detail || {};
  const editFunctionalityShow = role === authKey?.admin;
  const addBatchFunctionality = role === authKey?.instructor;
  const notShowingData = role === authKey?.student;
  const [editBatchModel, setEditBatchModel] = useState({
    type: false,
  });
  const [tableLoading, setTableLoading] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [addBachelor, setAddBachelor] = useState({ type: false });
  const {
    name,
    description,
    start_date,
    end_date,
    duration,
    class_days,
    classtimeto,
    classtimefrom,
    location,
    timezone,
    monthly_price,
    totalHours,
    numbers,
    link,
    enrolledStudents,
  } = data || {};

  const fetchDataCourseDetails = async () => {
    setTableLoading(true);
    try {
      const { response } = await courseDetailGetListApi(paginationData, id);
      setData(response?.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setTableLoading(false);
  };

  useEffect(() => {
    if (id !== null) {
      fetchDataCourseDetails();
    }
  }, [id]);

  const [enrolledStudentsCours, setEnrolledStudentsCours] = useState([]);

  const [enrolledStudentsLoading, setEnrolledStudentsLoading] = useState(false);
  const fetchData = async () => {
    setEnrolledStudentsLoading(true);
    try {
      const { response } = await studentsGetListApi(id);
      setEnrolledStudentsCours(response?.data?.students);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setEnrolledStudentsLoading(false);
  };
  const [courseBatchListData, setCourseBatchListData] = useState();
  // multi selecter
  const animatedComponents = makeAnimated();
  const [selectedOptionsBatch, setSelectedOptionsBatch] = useState({
    value: "",
    label: "",
  });
  const searchFields = ["sectionName"];

  const filterData = enrolledStudentsCours?.filter((user) => {
    // If no selected value, return true (no filtering applied)
    if (!selectedOptionsBatch?.label) return true;

    // Loop through the search fields and check for exact match
    return searchFields.some((field) => {
      const fieldValue = user[field]
        ? user[field].toString().trim().toLowerCase()
        : "";

      // Check for an exact match (not just a substring)
      return fieldValue === selectedOptionsBatch?.label.trim().toLowerCase();
    });
  });

  const assignCourseFunOff = filterData?.[0]?.evaluationSent;
  const handleChange = (selected) => {
    setSelectedOptionsBatch(selected);
  };
  const daysObject = courseBatchListData?.map((batchItem) => {
    const { _id, sectionName } = batchItem;

    return { value: _id, name: sectionName };
  });
  const optionBatch = daysObject?.map((daysItems) => {
    const { name, value } = daysItems || {};
    return { value: value, label: name };
  });
  const fetchDataCourseBatchList = async () => {
    try {
      const { response } = await GetCourseBatchCreateListApi(id);
      setCourseBatchListData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id !== null) {
      fetchData();
      fetchDataCourseBatchList();
    }
  }, [id]);

  const [loading, setLoading] = useState({ assignLoading: false });
  const onSubmitAssingCourseStudent = async () => {
    setLoading({ assignLoading: true });
    const payload = {
      courseId: id,
      studentIds: filterData?.map((studentItem) => studentItem?._id),
    };
    try {
      const res = await assignCourseStudentEvaluationQuestionApi(payload);
      // Check for a valid response
      if (res && res.status === 201) {
        fetchData();
      } else {
      }
    } catch (error) {
      // Show a single error toast for any unexpected errors.
      ToastHandle("An error occurred while submitting the course.", "danger");
    } finally {
      setLoading({ assignLoading: false });
    }
  };

  return (
    <>
      {!tableLoading ? (
        <div>
          <div className="flex  gap-[10px] justify-end">
            {editFunctionalityShow && (
              <button
                className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
                onClick={() => {
                  if (data) setEditModel({ editData: data, type: true });
                }}
              >
                <FaEdit />
              </button>
            )}
            <Link
              to={
                notShowingData
                  ? "/student/courses"
                  : addBatchFunctionality
                  ? "/instructor/course-management"
                  : `/course-management`
              }
              className=" bg-[#CC4D1D] text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
            >
              <GrClose />
            </Link>
          </div>

          <div className="bg-white rounded-[10px] shadow-lg mt-2 p-8 h-full overflow-y-auto">
            <h3 className="text-black font-medium text-3xl mb-2">{name}</h3>
            <p className="text-black font-normal text-sm leading-6">
              {description}
            </p>
            <div className="mt-8 flex flex-wrap gap-5 items-start justify-between">
              <div className="lg:w-[68%] w-full">
                <img
                  className="w-full h-[546px] object-cover rounded-[10px]"
                  src={Coursedetailimg}
                />
              </div>
              <div className="lg:w-[28%] w-full">
                <div className="bg-[#F3F3F3] rounded-[10px] p-5">
                  <div className="flex flex-wrap items-center justify-between mb-[30px]">
                    <p className="text-[626263] text-sm font-normal">
                      Start Date
                    </p>
                    <p className="text-[#CC4D1D] text-sm font-normal">
                      {start_date}
                    </p>
                  </div>
                  <div className="flex flex-wrap items-center justify-between mb-[30px]">
                    <p className="text-[626263] text-sm font-normal">
                      End Date
                    </p>
                    <p className="text-[#CC4D1D] text-sm font-normal">
                      {end_date}
                    </p>
                  </div>
                  <div className="flex flex-wrap items-center justify-between mb-[30px]">
                    <p className="text-[626263] text-sm font-normal">
                      Duration
                    </p>
                    <p className="text-[#CC4D1D] text-sm font-normal">
                      {duration} Weeks
                    </p>
                  </div>
                  <div className="flex flex-wrap items-center justify-between mb-[30px]">
                    <p className="text-[626263] text-sm font-normal">
                      Class Days
                    </p>
                    <p className="text-[#CC4D1D] text-sm font-normal">
                      {class_days?.map((days) => {
                        return `${days}, `;
                      })}
                    </p>
                  </div>
                  <div className="flex flex-wrap items-center justify-between mb-[30px]">
                    <p className="text-[626263] text-sm font-normal">
                      Class Time
                    </p>
                    <p className="text-[#CC4D1D] text-sm font-normal">
                      {classtimefrom && convertTo12HourFormat(classtimefrom)} to
                      {classtimeto && convertTo12HourFormat(classtimeto)}
                    </p>
                  </div>
                  <div className="flex flex-wrap items-center justify-between mb-[30px]">
                    <p className="text-[626263] text-sm font-normal">
                      Time Zone
                    </p>
                    <p className="text-[#CC4D1D] text-sm font-normal">
                      {timezone}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mb-[30px]">
                    <p className="text-[626263] text-sm font-normal">Cost </p>
                    <p className="text-[#CC4D1D] text-sm font-normal">
                      {monthly_price ? `$ ${monthly_price}` : "null"}
                    </p>
                  </div>
                  <div className="flex flex-wrap items-center justify-between mb-[30px]">
                    <p className="text-[626263] text-sm font-normal">
                      Location
                    </p>
                    <p className="text-[#CC4D1D] text-sm font-normal">
                      {location}
                    </p>
                  </div>
                  <div className="flex flex-wrap items-center justify-between mb-[30px]">
                    <p className="text-[626263] text-sm font-normal">
                      Categories
                    </p>
                    <p className="text-[#CC4D1D] text-sm font-normal">
                      {totalHours}
                    </p>
                  </div>
                  <div className="flex flex-wrap items-center justify-between mb-[30px]">
                    <p className="text-[626263] text-sm font-normal">
                      CE Hours
                    </p>
                    <p className="text-[#CC4D1D] text-sm font-normal">
                      {numbers}
                    </p>
                  </div>
                  {link && (
                    <div className="flex flex-wrap items-center justify-between mb-[30px]">
                      <p className="text-[626263] text-sm font-normal">
                        PPT Link
                      </p>

                      <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#CC4D1D] text-sm font-normal"
                      >
                        {truncateLink(link)}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!notShowingData && (
              <>
                <div className="flex justify-between items-center gap-2 flex-wrap my-3">
                  <div className="flex items-center">
                    <Select
                      styles={customStyles}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      defaultValue={selectedOptionsBatch}
                      value={selectedOptionsBatch}
                      onChange={handleChange}
                      options={optionBatch}
                      menuPlacement="top" // Position the dropdown above
                    />
                    {selectedOptionsBatch?.value !== "" && (
                      <>
                        <div
                          onClick={() => {
                            setSelectedOptionsBatch({ value: "", label: "" });
                          }}
                        >
                          <button className="bg-[#CC4D1D] ms-2 float-end text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0">
                            X
                          </button>
                        </div>
                        {!assignCourseFunOff && (
                          <div>
                            {console.log(
                              addBatchFunctionality,
                              "addBatchFunctionalityaddBatchFunctionality"
                            )}
                            {addBatchFunctionality && (
                              <button
                                onClick={onSubmitAssingCourseStudent}
                                className="bg-[#CC4D1D] ms-2 float-end text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
                              >
                                {!loading?.assignLoading ? (
                                  "Assigne Evaluation"
                                ) : (
                                  <LoaderButton />
                                )}
                              </button>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>

                  {addBatchFunctionality && (
                    <div className="flex gap-1 items-center">
                      <button
                        onClick={() => {
                          setAddBachelor({
                            type: true,
                            studentsAll: enrolledStudentsCours,
                          });
                        }}
                        className="bg-[#CC4D1D] float-end text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
                      >
                        Add Student Batch
                      </button>
                      {!assignCourseFunOff &&
                        selectedOptionsBatch?.value !== "" && (
                          <button
                            className="bg-[#CC4D1D] float-end text-white font-medium text-xs rounded-[5px] py-2 px-3 rounded inline-flex items-center border border-[#CC4D1D] focus:outline-0"
                            onClick={() => {
                              setEditBatchModel({
                                type: true,
                                editData: filterData,
                                studentsAll: enrolledStudentsCours,
                                editDataEmpty: { selectedOptionsBatch },
                              });
                            }}
                          >
                            Edit
                          </button>
                        )}
                    </div>
                  )}
                </div>

                <StudentBacheTable
                  bacheStudentData={filterData}
                  updateAfterRes={() => {
                    fetchData();
                  }}
                  tableLoading={enrolledStudentsLoading}
                />
              </>
            )}

            <AddSectionForm
              show={addBachelor}
              hide={() => {
                setAddBachelor({ type: false });
              }}
              updateAfterRes={() => {
                fetchData();
                fetchDataCourseBatchList();
              }}
            />
            <EditBatchForm
              show={editBatchModel}
              hide={() => {
                setEditBatchModel({ type: false });
              }}
              updateAfterRes={() => {
                fetchData();
                fetchDataCourseBatchList();
              }}
            />
          </div>
        </div>
      ) : (
        <TableLorder />
      )}

      <CourseEditModel
        show={editModel}
        hide={() => {
          setEditModel({ type: false });
        }}
        updateAfterRes={() => fetchDataCourseDetails()}
      />
    </>
  );
};

export default CourseManagementProfileView;
