import React, { useEffect, useState } from "react";
import { addHospitalData } from "../../../../helper/apis/apis";
import { useForm } from "react-hook-form";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../../helper/ErrorMessage";
import { LoaderButton } from "../../../../../Components/commonFunction/Loader";
import Select from "react-select";
import { instructorGetListApi } from "../../../../helper/apis/instructor/instructorSection";
import { agencyGetCreateByApi } from "../../../../helper/apis/hospital/hospital";
import ToastHandle from "../../../../helper/ToastMessage";
const HopitalAddModel = ({ show, hide, updateAfterRes }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [formLoading, setFormLoading] = useState(false);

  const [instructorData, setInstructorData] = useState([]);
  const options = instructorData?.map((inteructorItems) => {
    const { name, _id } = inteructorItems;
    return { value: _id, label: name };
  });

  const [agencyData, setAgencyData] = useState([]);
  const optionsAgency = agencyData?.map((inteructorItems) => {
    const { name, _id } = inteructorItems;
    return { value: _id, label: name };
  });

  const [selectedMedical, setSelectedMedical] = useState(null);
  const [selectedEms, setSelectedEms] = useState(null);
  const [selectAgency, setSelectAgency] = useState(null);
  const onSubmit = async (data) => {
    const twoIdMedicalEms = { selectedMedical, selectedEms, selectAgency };
    setFormLoading(true);
    try {
      const res = await addHospitalData(data, twoIdMedicalEms);
      if (res.status) {
        hide(false);
        updateAfterRes();
        reset({
          hospitalName: "",
          location: "",
          medicalControl: "",
          emsCoordinator: "",
          hospitalImage: "",
        });
      }
    } catch (error) {
      ToastHandle(error.response?.data?.message, "danger");
    }
    setFormLoading(false);
  };
  const fetchDataAgency = async () => {
    try {
      const { response } = await agencyGetCreateByApi();
      setAgencyData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const data = { page: 1, limit: 10000 };
      try {
        const { convertData } = await instructorGetListApi(data);
        setInstructorData(convertData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    fetchDataAgency();
  }, []);

  return (
    <>
      {show?.type ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[60%] w-[90%]  popup">
              {/* content */}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">
                    Hospital Add
                  </h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={() => hide()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto">
                  <div className="form">{/* dynamic content */}</div>
                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Hospital Name
                    </label>
                    <input
                      type="text"
                      {...register("hospitalName", {
                        required: true,
                      })}
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    {errors.hospitalName && (
                      <>
                        {errorMessageShow(
                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                        )}
                      </>
                    )}
                  </div>

                  <div className="my-3">
                    <label className="block mb-2 text-sm font-normal text-[#727272] ">
                      Location
                    </label>
                    <textarea
                      type="text"
                      {...register("location", {
                        required: true,
                      })}
                      className="border border-[#D9D9D9] p-3 h-[100px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    {errors.location && (
                      <>
                        {errorMessageShow(
                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                        )}
                      </>
                    )}
                  </div>
                  <div className="mb-3">
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Agency
                    </label>
                    <Select
                      defaultValue={selectAgency}
                      onChange={setSelectAgency}
                      options={optionsAgency}
                      isMulti
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Medical Control
                    </label>
                    <Select
                      defaultValue={selectedMedical}
                      onChange={setSelectedMedical}
                      options={options}
                    />
                    {errors.medicalControl && (
                      <>
                        {errorMessageShow(
                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                        )}
                      </>
                    )}
                  </div>
                  <div className="my-3">
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      EMS Coordinator
                    </label>
                    <Select
                      defaultValue={selectedEms}
                      onChange={setSelectedEms}
                      options={options}
                    />
                    {errors.emsCoordinator && (
                      <>
                        {errorMessageShow(
                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                        )}
                      </>
                    )}
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-normal text-[#727272]">
                      Hospital Image
                    </label>
                    <input
                      type="file"
                      {...register("hospitalImage")}
                      className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                    />
                    {errors.hospitalImage && (
                      <>
                        {errorMessageShow(
                          errorEndPoint?.THIS_FIELD_IS_REQUIRED
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* footer */}
                <div className="flex items-center justify-end px-6 py-4 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => hide()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    // onClick={handleSubmit(onSubmit)}
                    onClick={handleSubmit(
                      (data) => {
                        onSubmit(data);
                      },
                      (err) => {
                        console.log(err, "ee");
                      }
                    )}
                  >
                    {!formLoading ? "Add" : <LoaderButton />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default HopitalAddModel;
