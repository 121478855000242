import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { requiredStar } from "../../../../../../../Components/commonFunction/CommonFunction";
import {
  errorEndPoint,
  errorMessageShow,
} from "../../../../../../helper/ErrorMessage";
import { LoaderButton } from "../../../../../../../Components/commonFunction/Loader";
import { addEvaluationQuestionApi } from "../../../../../../helper/apis/evaluation/evaluationApis";
import ToastHandle from "../../../../../../helper/ToastMessage";
const EvaluationQuestAddForm = ({ show, hide, updateAfterRes }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue
  } = useForm();

  const [formLoading, setFormLoading] = useState(false);
  const onSubmit = async (data) => {
    setFormLoading(true);
    try {
      const res = await addEvaluationQuestionApi(data);
      if (res.status) {
        hide();
        updateAfterRes();
        reset({
          questionN: "",
          rating: "",
        });
      }
    } catch (error) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
    setFormLoading(false);
  };

  return (
    <>
      {show ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative my-6 lg:w-[60%] w-[90%]  popup">
              {/* content */}
              <div className="border-0 rounded-[20px] shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/* header */}
                <div className="flex items-center justify-between p-3 border-b border-solid border-[#EEEEEE] rounded-t">
                  <h3 className="text-lg font-medium text-black">
                    Create New Evaluation Question
                  </h3>
                  <button
                    className="ml-auto bg-transparent border-0 text-black float-right text-3xl font-normal outline-none focus:outline-none pb-2"
                    onClick={() => hide()}
                  >
                    <span className="bg-transparent text-[#9E9E9E] h-6 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/* body */}
                <div className="relative p-6 flex-auto">
                  <div className="form">{/* dynamic content */}</div>
                  <div className="grid md:grid-cols-1 grid-cols-1 gap-5">
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272] ">
                        Evaluation Question {requiredStar}
                      </label>
                      <input
                        type="text"
                        {...register("questionN", {
                          required: "This field is required",
                          validate: (value) =>
                            value.trim() !== "" ||
                            "This field cannot be just spaces",
                        })}
                        onInput={(e) => {
                          if(e.target.value.trim() === ""){
                            setValue("questionN", "")
                          }
                        }}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                        placeholder="Enter question"
                      />

                      {errors.questionN && (
                        <span className="text-red-500 text-xs">
                          {errors.questionN.message}
                        </span>
                      )}
                    </div>
                    <div className="">
                      <label className="block mb-2 text-sm font-normal text-[#727272]">
                        Number of Rating {requiredStar}
                      </label>
                      <input
                        type="number"
                        {...register("rating", {
                          required: "This field is required",
                          min: {
                            value: 1,
                            message: "Rating must be at least 1",
                          },
                          max: {
                            value: 5,
                            message: "Rating cannot exceed 5",
                          },
                          validate: (value) => {
                            return /^[1-5]$/.test(value) || "Rating must be an integer between 1 and 5";
                          }
                        })}
                        className="border border-[#D9D9D9] p-3 h-[50px] w-full rounded-[10px] focus:outline-0 text-sm text-black font-normal placeholder:text-[#727272]"
                      />
                      {/* Display error messages */}
                      {errors.rating && (
                        <p className="text-red-500 text-sm mt-2">
                          {errors.rating.message || "This field is required"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* footer */}
                <div className="flex items-center justify-end px-6 py-4 border-t border-solid border-[#EEEEEE] rounded-b gap-5">
                  <button
                    className="text-white bg-[#CCCCCC] font-medium px-10 py-3.5 text-sm outline-none rounded-[5px] focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => hide()}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-[#CC4D1D] text-white active:bg-emerald-600 font-medium text-sm px-10 py-3.5 rounded-[5px] shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmit(
                      (data) => {
                        onSubmit(data);
                      },
                      (err) => {
                        console.log(err, "ee");
                      }
                    )}
                  >
                    {!formLoading ? "Add" : <LoaderButton />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default EvaluationQuestAddForm;
