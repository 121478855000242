import React, { useEffect, useState } from "react";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import { PiTrashBold } from "react-icons/pi";
import DeleteModel from "../../admin/helper/DeleteModel";
import { DataEmpty } from "../commonFunction/CommonFunction";
import { TableLorder } from "../../admin/helper/Lorder";

const Table = ({
  columns,
  data,
  manageButton = false,
  iconsShow = false,
  modelModel = () => {},
  showModal = () => {},
  navigateHndle = () => {},
  otherAction,
  noAction = false,
  verifyHndle = () => {},
  deleteHndle,
  deleteLoading,
  DeleteModelClose,
  setDeleteModelClose,
  tableLoading = false,
  notShowing = false,
  editButtonNotShowing = false,
}) => {
  const [deleteModel, setDeleteModel] = useState({
    deleteShowType: false,
    deleteId: "",
    deleteItem: "",
  });

  const closeDeleteModel = () => {
    setDeleteModel({
      deleteShowType: false,
      deleteId: "",
      deleteItem: "",
    });
    setDeleteModelClose(false);
  };

  useEffect(() => {
    if (DeleteModelClose) {
      closeDeleteModel();
    }
  }, [DeleteModelClose]);

  return (
    <div className="table-section table-responsive rounded-[15px] w-full bg-white">
      {!tableLoading ? (
        <>
          {data?.length > 0 ? (
            <table className="w-full h-full table-auto table-layout-fixed">
              <thead className="bg-[#d9d9d9] h-[60px]">
                <tr>
                  {columns?.map((column, index) => (
                    <th
                      key={index}
                      className="text-[#CC4D1D] text-sm font-semibold text-left px-5 py-3"
                    >
                      {column.header}
                    </th>
                  ))}
                  {!noAction && (
                    <th className="text-[#CC4D1D] text-sm  font-semibold text-left py-3 px-4">
                      Action
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {data?.map((row, index) => {
                  const { _id, name, image } = row;

                  return (
                    <tr
                      key={index}
                      className="h-[60px] border-b border-[#F4F5F9]"
                    >
                      {columns?.map((column, colIndex) => {
                        return (
                          <td
                            key={colIndex}
                            className="text-[#89868D] text-sm  font-normal px-5 py-3"
                          >
                            {column.render ? (
                              <p onClick={() => verifyHndle(_id)}>
                                {column.render(row[column.field], row)}
                              </p>
                            ) : (
                              row[column.field]
                            )}
                          </td>
                        );
                      })}

                      {!noAction && (
                        <td>
                          {!otherAction ? (
                            <div className="flex items-center gap-3">
                              {manageButton ? (
                                <button
                                  onClick={() => showModal(row)}
                                  className="text-sm text-white rounded-[5px] bg-[#01AF7B] py-1.5 px-2.5"
                                >
                                  Manage Evaluations
                                </button>
                              ) : (
                                <>
                                  {!iconsShow ? (
                                    <button
                                      className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                                      onClick={() => navigateHndle(_id)}
                                    >
                                      <MdOutlineRemoveRedEye className="text-[#CC4D1D]" />
                                    </button>
                                  ) : (
                                    ""
                                  )}
                                  {!notShowing && (
                                    <>
                                      {!editButtonNotShowing && (
                                        <button
                                          onClick={() => modelModel(row)}
                                          className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                                        >
                                          <FiEdit className="text-[#CC4D1D]" />
                                        </button>
                                      )}

                                      <button
                                        onClick={() =>
                                          setDeleteModel({
                                            deleteShowType: true,
                                            deleteId: _id,
                                            deleteItem: name,
                                          })
                                        }
                                        className="text-xl bg-[#F8F8F8] rounded-[5px] h-[30px] w-[30px] flex items-center justify-center"
                                      >
                                        <PiTrashBold className="text-[#CC4D1D]" />
                                      </button>
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          ) : (
                            <div className="flex items-center gap-3">
                              <div>...</div>
                            </div>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <DataEmpty />
          )}
        </>
      ) : (
        <TableLorder />
      )}

      <DeleteModel
        show={deleteModel?.deleteShowType}
        hide={() =>
          setDeleteModel({
            deleteShowType: false,
            deleteId: "",
          })
        }
        deleteItem={deleteModel?.deleteItem}
        confimDelete={() => deleteHndle(deleteModel?.deleteId)}
        deleteLoading={deleteLoading}
      />
    </div>
  );
};

export default Table;
