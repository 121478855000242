const SearchFilter = (data, searchValue) => {
  if (typeof searchValue !== "string") {
    return [];
  }

  const inputValue = searchValue.toLowerCase();
  const allSearchData = data?.filter((users) => {
    const {
      name,
      certificationNumber,
      email,
      phoneNumber,
      description,
      monthly_price,
      start_date,
      evaluationname,
      doctor,
      apnumber,
      program,
      hospital,
      agencyname,
      address,
      chiefname,
      chiefphone,
      chiefemail,
      hospitalname,
      location,
      ems,
      sectionName,
      courseName,
    } = users ? users : [];

    const mainEmail = email?.toLowerCase() || "";
    const mainName = name?.toLowerCase() || "";
    const mainCrtification = certificationNumber?.toLowerCase() || "";
    const mainPhoneNumber = phoneNumber?.toLowerCase() || "";
    const mainDescription = description?.toLowerCase() || "";
    const mainMonthlyPrice = monthly_price?.toLowerCase() || "";
    const mainStartDate = start_date?.toLowerCase() || "";
    const mainEvaluationname = evaluationname?.toLowerCase() || "";
    const mainDoctor = doctor?.toLowerCase() || "";
    const mainApnumber = apnumber?.toLowerCase() || "";
    const mainProgram = program?.toLowerCase() || "";
    const mainHospital = hospital?.[0]?.toLowerCase() || "";
    const mainAgencyName = agencyname?.toLowerCase() || "";
    const mainAddress = address?.toLowerCase() || "";
    const mainChiefname = chiefname?.toLowerCase() || "";
    const mainChiefphone = chiefphone?.toLowerCase() || "";
    const mainChiefEmail = chiefemail?.toLowerCase() || "";
    const mainHospitalName = hospitalname?.toLowerCase() || "";
    const mainLocation = location?.toLowerCase() || "";
    const mainEms = ems?.toLowerCase() || "";
    const mainSectionName = sectionName?.toLowerCase() || "";
    const mainCourseName = courseName?.toLowerCase() || "";
    //    search section
    const emailSearch = mainEmail.includes(inputValue);
    const nameSearch = mainName.includes(inputValue);
    const crtificationSearch = mainCrtification.includes(inputValue);
    const phoneNumberSearch = mainPhoneNumber.includes(inputValue);
    const descriptionSearch = mainDescription.includes(inputValue);
    const monthlyPriceSearch = mainMonthlyPrice.includes(inputValue);
    const startDateSearch = mainStartDate.includes(inputValue);
    const EvaluationnameSearch = mainEvaluationname.includes(inputValue);
    const doctorSearch = mainDoctor.includes(inputValue);
    const apnumberSearch = mainApnumber.includes(inputValue);
    const programSearch = mainProgram.includes(inputValue);
    const hospitalSearch = mainHospital.includes(inputValue);
    const agencyNameSearch = mainAgencyName.includes(inputValue);
    const addressSearch = mainAddress.includes(inputValue);
    const chiefNameSearch = mainChiefname.includes(inputValue);
    const chiefPhoneSearch = mainChiefphone.includes(inputValue);
    const chiefEmailSearch = mainChiefEmail.includes(inputValue);
    const hospitalNameSearch = mainHospitalName.includes(inputValue);
    const locationSearch = mainLocation.includes(inputValue);
    const emsSearch = mainEms.includes(inputValue);
    const sectionNameSearch = mainSectionName.includes(inputValue);
    const CourseNameSearch = mainCourseName.includes(inputValue);
    return (
      emailSearch ||
      nameSearch ||
      crtificationSearch ||
      phoneNumberSearch ||
      descriptionSearch ||
      monthlyPriceSearch ||
      startDateSearch ||
      EvaluationnameSearch ||
      doctorSearch ||
      apnumberSearch ||
      programSearch ||
      hospitalSearch ||
      agencyNameSearch ||
      addressSearch ||
      chiefNameSearch ||
      chiefPhoneSearch ||
      chiefEmailSearch ||
      hospitalNameSearch ||
      locationSearch ||
      emsSearch ||
      sectionNameSearch ||
      CourseNameSearch
    );
  });

  return allSearchData;
};

export default SearchFilter;
