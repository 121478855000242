import { USERS_ADD_HOSPITAL, USERS_UPDATE_HOSPITAL } from "../ApiEndPoint";
import axiosInstance, {
  BASE_URL,
} from "../../../Components/apiHeader/axiosInstance";
import ToastHandle from "../ToastMessage";

export const addHospitalData = async (data, ids) => {
  const { selectedEms, selectedMedical, selectAgency } = ids || {};
  const { hospitalName, location, hospitalImage } = data;
  let formData = new FormData();
  formData.append("name", hospitalName);
  formData.append("location", location);
  formData.append("medical_control", selectedMedical?.value);
  formData.append("ems_coordinator", selectedEms?.value);
  formData.append("image", hospitalImage[0]);
  formData.append(
    "agency",
    JSON.stringify(selectAgency?.map((agencyItem) => agencyItem?.value))
  );

  try {
    // Send the POST request
    const response = await axiosInstance.post(
      `${BASE_URL}${USERS_ADD_HOSPITAL}`,
      formData
    );

    // Check if response and response.data are valid
    if (response && response.data) {
      const responseMessage = response?.data?.message;
      if (response.status === 200) {
        // Assuming 200 is the success status
        ToastHandle(responseMessage, "success");
        return response;
      } else {
        // Handle any non-200 status codes here if necessary
        ToastHandle(
          responseMessage || "An unexpected error occurred",
          "danger"
        );
      }
    }
  } catch (error) {
    // Check if error response exists and handle accordingly
    if (error?.response) {
      const errorMessage =
        error.response?.data?.message || "Something went wrong.";
      const errorStatus = error.response?.status;

      if (errorStatus === 400) {
        ToastHandle(errorMessage, "danger");
      } else {
        // Handle other error statuses here
        ToastHandle(errorMessage, "danger");
      }
    } else {
      // If there's no response from the server, show a generic error message
      ToastHandle("Network error or no response from server", "danger");
    }
  }
};

export const updateHospitalData = async (data, mainId, ids) => {
  const { selectedEms, selectedMedical ,selectAgency} = ids || {};

  const { hospitalName, location, hospitalImage } = data;
  let formData = new FormData();
  formData.append("id", mainId);
  formData.append("name", hospitalName);
  formData.append("location", location);
  formData.append("medical_control", selectedMedical?.value);
  formData.append("ems_coordinator", selectedEms?.value);
  formData.append(
    "agency",
    JSON.stringify(selectAgency?.map((agencyItem) => agencyItem?.value))
  );
  if (hospitalImage[0] != 1) {
    formData.append("image", hospitalImage[0]);
  }

  try {
    const response = await axiosInstance.post(
      `${BASE_URL}${USERS_UPDATE_HOSPITAL}`,
      formData
    );
    const responseMessage = response?.data?.message;
    if (response.status) {
      ToastHandle(responseMessage, "success");
      return response;
    }
  } catch (error) {
    if (error?.response?.status === 400) {
      ToastHandle(error?.response?.data?.message, "danger");
    }
  }
};
